<template>
  <div>
      <Container v-if="isshow" :mainpagedata="mainpagedata" :mydefault="mydefault"></Container>
  </div>
</template>

<script>
import Container from '@/components/Container.vue'
import {defineComponent} from "vue";

export default defineComponent({
  name: "exportlist",
  data() {
    return {
      mainpagedata: {
        //特殊页面
        showpages: { //判断pages页面，常规通用组件页面显示pages，特殊页面显示special
          isshowpages: false,      //显示pages的内容
          isshowspecial: true,   //显示special的内容
          mycontrol: 213, //当该页面为special页面内容时，用数字控制页面-详情内容显示
          content: {},  //特殊页面中模块的配置数据
        },
      }, // 当前Pagedata所需的data数据与控制
      mydefault: {
        myheadactive: "a3",    //该字段为string类型，是头部导航key值
        myasideactive: "539",  //该字段为string类型，是子页面的id
      }, //本页面的导航默认值-逆向绑定（可优化-通过当前页面的path值，对数据进行筛选对比，将值自动赋值，避免修改路由之后出现页面&导航不对应状况）
      mytoken: localStorage.getItem('token'),  //获取存储的token
      isshow: true  //用于判断页面模块是否存在

    }
  },
  created() {
    if (this.mytoken === null) {
      this.isshow = false
      this.$router.push('/').catch(error => error)
    }
  },
  components: {
    Container,
  }
})
</script>

<style scoped>

</style>
